.carousel {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 28px;

  /* stylelint-disable selector-max-compound-selectors */
  /* stylelint-disable selector-max-combinators */
  & :global {
    .swiper-pagination {
      position: static;
      order: 1;
      margin-top: 28px;
    }
  }

  /* stylelint-enable selector-max-compound-selectors */
  /* stylelint-enable selector-max-combinators */
}

.slideImg {
  display: block;
  width: 100%;
}
