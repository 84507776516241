@import '../../styles/mixins.scss';

.articlePreview {
  @include content-max-width;
  @include css-lock-by-width(30, 50, $mobile-min-width, $desktop-content-max-width, padding);
  @include css-lock-by-width(18, 50, $mobile-min-width, $desktop-content-max-width, padding-left);
  @include css-lock-by-width(18, 50, $mobile-min-width, $desktop-content-max-width, padding-right);
  display: flex;
  flex-direction: column;
  border: 2px solid $color-firm14;
  border-radius: 5px;
}

.title {
  @include heading-underline($color-firm1);
}

.text {
  margin-top: 43px;
  font-size: $font-size9;
  line-height: 1.3;
}

.link {
  @include css-lock-by-width(14, 26, $mobile-min-width, $desktop-content-max-width, margin-top);
  margin-left: auto;
}
