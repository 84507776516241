// Colors BEGIN
$color-white: #ffffff;
$color-black: #000000;
$color-firm1: #a9187b;
$color-firm2: #aa1779;
$color-firm3: #990066;
$color-firm4: #2a1073;
$color-firm5: #290e72;
$color-firm6: #290f72;
$color-firm7: #2e1475;
$color-firm8: #2e1475;
$color-firm9: #333333;
$color-firm10: #666666;
$color-firm11: #999999;
$color-firm12: #f0f0f0;
$color-firm13: #f3f3f3;
$color-firm14: #efefef;
$color-firm15: #e5e5e5;
$color-firm16: #f6f6f6;
$color-firm17: #bcbbbb;
$color-firm18: #cccccc;
$color-firm19: #eeeeee;
$color-firm20: #e8e8e8;
$color-firm21: #323232;
$color-firm22: #339900;
$color-firm23: #e6e6e6;
$color-firm24: #ff0000;
// Colors END

// Fonts BEGIN
$ff-primary: 'Montserrat', sans-serif;
// Fonts END

// Font-sizes BEGIN
$font-size1: 48px;
$font-size2: 36px;
$font-size3: 30px;
$font-size4: 26px;
$font-size5: 24px;
$font-size6: 20px;
$font-size7: 18px;
$font-size8: 16px;
$font-size9: 14px;
$font-size10: 13px;
$font-size11: 12px;
$font-size12: 10px;
$font-size13: 9px;
$font-size14: 6px;
// Font-sizes END

// General BEGIN
$animation-time: 0.3s;
$hover-opacity: 0.7;
$active-opacity: 0.6;

$content-max-width: 1140px;
// General END

// Button BEGIN
$button-color: $color-white;
$button-primary-background-color: $color-firm1;
$button-secondary-background-color: $color-firm9;
$button-tertiary-background-color: $color-white;
// Button END

// RoundButton BEGIN
$round-button-primary-color: $button-primary-background-color;
$round-button-secondary-color: $color-firm5;
$round-button-tertiary-color: $button-tertiary-background-color;
$round-button-tertiary-icon-color: $color-firm5;
// RoundButton END

// Badge BEGIN
$badge-min-height: 50;
// Badge END

// Link BEGIN
$link-primary-color: $color-firm1;
$link-primary-hover-color: $color-firm2;
$link-primary-active-color: rgba($color-firm2, 0.6);
$link-secondary-color: $color-firm9;
$link-secondary-hover-color: $color-firm4;
$link-secondary-active-color: rgba($color-firm4, 0.6);
$link-tertiary-color: $color-white;
$link-tertiary-hover-color: $color-white;
$link-tertiary-active-color: rgba($color-white, 0.6);
$link-quaternary-color: $color-firm9;
$link-quaternary-hover-color: $color-firm10;
$link-quaternary-active-color: rgba($color-firm10, 0.6);
// Link END

// TextField BEGIN
$text-field-label-color: $color-firm11;
$text-field-input-color: $color-firm9;
$text-field-placeholder-color: $color-firm9;
$text-field-primary-background-color: $color-firm16;
$text-field-primary-hover-focus-background-color: $color-firm15;
$text-field-primary-active-background-color: rgba($color-firm15, 0.5);
$text-field-secondary-background-color: $color-white;
$text-field-secondary-hover-focus-border-color: $color-firm4;
$text-field-secondary-active-background-color: rgba($color-white, 0.5);
$text-field-error-border-color: $color-firm24;
$text-field-disabled-opacity: 0.7;
$text-field-helper-text-color: $color-firm1;
// TextField END

// Checkbox BEGIN
$checkbox-checkmark-color: $color-firm9;
$checkbox-border-color: $color-firm20;
$checkbox-border-color-focus: $color-firm11;
$checkbox-border-color-active: $color-firm10;
$checkbox-disabled-opacity: 0.6;
// Checkbox END

// Select BEGIN
$select-background-color: $color-firm16;
$select-hover-border-color: $color-firm5;
$select-color: $color-firm9;
$select-single-value-color: $color-firm10;
$select-item-hover-background: rgba($color-firm11, 0.3);
$select-item-selected-background: $color-firm11;
$select-placeholder-color: $color-firm9;
$select-multi-value-remove-hover-background-color: rgba($color-firm4, 0.3);
$select-multi-value-remove-hover-color: $color-firm1;
$select-multi-value-remove-active-background-color: rgba($color-firm4, 0.2);
$select-multi-value-remove-active-color: $color-firm1;
$select-dropdown-indicator-color: $color-firm4;
$select-error-message-color: $color-firm1;
$select-label-color: $color-firm9;
$select-indicator-hover-color: $color-firm10;
// Select END

// Pagination BEGIN
$pagination-link-color: $color-firm9;
$pagination-active-color: $color-white;
$pagination-active-background: $color-firm9;
$pagination-hover-background: rgba($color-firm9, 0.7);
$pagination-hover-color: $color-white;
//Pagination END

// Spinner BEGIN
$spinner-color1: #008744;
$spinner-color2: #0057e7;
$spinner-color3: #d62d20;
$spinner-color4: #ffa700;
// Spinner END

// Overlay BEGIN
$overlay-backdrop-black-color: rgba($color-black, 0.8);
$overlay-backdrop-white-color: rgba($color-white, 0.7);
// Overlay END

// Resize BEGIN
$desktop-max-width: 1920;
$desktop-min-width: 1200;
$tablet-max-width: 1024;
$mobile-max-width: 736;
$mobile-small-width: 375;
$mobile-min-width: 320;

$desktop-content-max-width: 1160;
// Resize END

// Z-Index BEGIN
// Z-Index END

// region Export to JS
/* stylelint-disable property-case */
:export {
  mobileMaxWidth: $mobile-max-width;
}

/* stylelint-enable property-case */
//endregion
