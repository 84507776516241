@import '../../styles/mixins.scss';

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.home {
  @include content-max-width;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding-top: 20px;
  padding-bottom: 50px;
}

.logoLink {
  @include default-hover-focus-active;
  @include css-lock-by-width(138, 206, $mobile-min-width, $desktop-content-max-width, width);
  display: block;
  margin: 0 auto;
}

.logoImg {
  display: block;
  width: 100%;
}

.promotions {
  margin-top: 30px;
}

.citySelection {
  @include css-lock-by-width(76, 70, $mobile-min-width, $desktop-content-max-width, margin-top);
}

.spinner {
  width: 80px;
  margin: 80px auto 0;
}

.aboutUs {
  margin-top: 70px;
}

.contactNumber {
  display: none;
}

@media (max-width: #{$mobile-max-width}px) {
  .main {
    background:
      url('/images/stocking.png') no-repeat top 190px right -90px / 280px,
      url('/images/bag.png') no-repeat bottom linear-dynamic-size-relate-vw(-30, -25, $mobile-min-width, $mobile-max-width) left -205px / linear-dynamic-size-relate-vw(290, 312, $mobile-min-width, $mobile-max-width);
  }

  .promotions,
  .aboutUs {
    display: none;
  }

  .spinner {
    margin-top: auto;
  }

  .contactNumber {
    display: flex;
    margin-top: auto;
  }
}
