@use 'sass:math';

@import './variables.scss';
@import './animations.scss';
@import './functions.scss';

@mixin ghost-vertical-align-middle {
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0;
  }
}

@mixin custom-scrollbar($size: 0.6em, $color-thumb: #727272, $color-track: rgba(#727272, 0.3)) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-track {
    background-color: $color-track;
    border-radius: 0.31em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-thumb;
    border-radius: 5px;

    &:active {
      background-color: rgba($color-thumb, 0.7);
    }
  }
}

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;
  $extmods: (eot: '?', svg: '#' + str-replace($name, ' ', '_'));
  $formats: (otf: 'opentype',ttf: 'truetype');
  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma); /* stylelint-disable-line function-url-quotes */
    @font-face {
      font-display: auto;
      font-family: quote($name);
      font-style: $style;
      font-weight: $weight;
      src: $src;
    }
  }
}

@mixin placeholder-color($color) {
  &::placeholder {
    color: $color;
  }
}

@mixin smooth-resize($max-size, $property: font-size, $unit: px, $min-width: $mobile-min-width, $max-width: $desktop-content-max-width) {
  $max-size: strip-unit($max-size);
  $resizeCoefficient: math.div($min-width, $max-width);
  $min-size: $resizeCoefficient * $max-size;
  @include css-lock-by-width($min-size, $max-size, $min-width, $max-width, $property, $unit);
}

// TODO: Complete resize BEGIN
@mixin fontSize1 {
  @include css-lock-by-width($font-size1, $font-size1, $mobile-min-width, $desktop-content-max-width);
}

@mixin fontSize2 {
  @include css-lock-by-width($font-size2, $font-size2, $mobile-min-width, $desktop-content-max-width);
}

@mixin fontSize3 {
  @include css-lock-by-width($font-size6, $font-size3, $mobile-min-width, $desktop-content-max-width);
}

@mixin fontSize4 {
  @include css-lock-by-width($font-size4, $font-size4, $mobile-min-width, $desktop-content-max-width);
}

@mixin fontSize7 {
  @include css-lock-by-width($font-size7, $font-size7, $mobile-min-width, $desktop-content-max-width);
}

@mixin fontSize9 {
  @include css-lock-by-width($font-size9, $font-size9, $mobile-min-width, $desktop-content-max-width);
}
// TODO: Complete resize END

@mixin default-hover-focus-active {
  cursor: pointer;
  transition: opacity $animation-time;

  &:hover,
  &:focus {
    opacity: $hover-opacity;
  }

  &:active {
    opacity: $active-opacity;
  }
}

$desktop-max-width-horizontal-padding: 20px;
$mobile-max-width-horizontal-padding: 15px;

@mixin content-max-width {
  @include css-lock-by-width(
    $mobile-max-width-horizontal-padding, $desktop-max-width-horizontal-padding, $mobile-min-width, $desktop-content-max-width, padding-left
  );
  @include css-lock-by-width(
    $mobile-max-width-horizontal-padding, $desktop-max-width-horizontal-padding, $mobile-min-width, $desktop-content-max-width,
    padding-right,
  );
  width: 100%;
  max-width: $content-max-width + $desktop-max-width-horizontal-padding * 2;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0 auto;
}

@mixin clearfix {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

@mixin force-min-aspect-ratio($width, $height) {
  @include clearfix;

  &:before {
    content: '';
    float: right;
    height: 0;
    padding-top: calc(#{$height} / #{$width} * 100%);
  }
}

@mixin heading-underline($backgroundColor: currentColor, $position: 'left') {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    display: block;
    top: calc(100% + 0.45em);
    width: 30px;
    height: 2px;
    background-color: $backgroundColor;

    @if ($position == 'left') {
      left: 0;
    } @else if ($position == 'center') {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

@mixin media-tablet {
  @media (max-width: #{$tablet-max-width}px) {
    @content;
  }
}

@mixin media-phone {
  @media (max-width: #{$mobile-max-width}px) {
    @content;
  }
}
